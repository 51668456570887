@import "./_variables";
@import "./_mixins";

@keyframes cloud {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(50%);
    }
}

@keyframes train {
    from {
        transform: translate(38%, 10%) scale(0.55);
    }

    to {
        transform: translate(-266%, -77%) scale(4);
    }
}

.index {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 1940px;

    &-cloud {
        position: absolute;

        .-wrongBrowser & {
            display: none;
        }

        img {
            width: 100%;
            mix-blend-mode: screen;
            transform: translateX(-50%);
        }

        &.-c01 {
            top: 15px;
            left: -366px;
            width: 1133px;

            img {
                animation: cloud 10s linear infinite alternate;
            }
        }

        &.-c02 {
            top: 940px;
            right: -299px;
            width: 746px;

            img {
                animation: cloud 25s linear infinite alternate;
            }
        }
    }

    &-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &-train {
        position: absolute;
        bottom: -420px;
        left: 0;
        width: 1970px;

        img {
            width: 100%;
            transform: translate(38%, 10%) scale(0.55);

            body:not(.-wrongBrowser).-loaded & {
                animation: train 4s cubic-bezier(0.75, 0.1, 1, 0.9);
            }
        }
    }

    &-product {
        position: absolute;
        bottom: -30px;
        right: 60px;
        display: flex;
        width: 368px;

        img {
            width: 100%;
        }
    }

    &-line {
        position: absolute;
        bottom: 0;
        right: -5px;
        width: 1065px;
        height: 2001px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @include breakpoint-down('tablet') {
        min-height: percent(1940);

        &-cloud {
            &.-c01 {
                top: percent(15);
                left: percent(-366);
                width: percent(1133);
            }

            &.-c02 {
                top: percent(940);
                right: percent(-299);
                width: percent(746);
            }
        }

        &-train {
            bottom: percent(-420);
            width: percent(1970);
        }

        &-product {
            bottom: percent(-30);
            right: percent(60);
            width: percent(368);
        }

        &-line {
            right: percent(-5);
            width: percent(1065);
            height: percent(2001);
        }
    }
}

$cerealsPosition: (
    (180, 18),
    (105, 0),
    (35, 56),
    (10, 152),
    (5, 268),
    (30, 380),
    (90, 460),
    (178, 440),
    (260, 390),
    (330, 420),
    (375, 500),
    (420, 595),
    (465, 685),
    (525, 760),
    (580, 805),
    (650, 870),

    (730, 910),
    (825, 920),
    (915, 890),
    (1010, 840),
    (1070, 760),
    (1115, 670),
    (1140, 565),
    (1170, 450),
    (1210, 345),
    (1265, 265),
    (1340, 195),
    (1425, 150),
    (1510, 125),
    (1610, 120),
    (1690, 130),
    (1780, 160),

    (1855, 210),
    (1925, 270),
);

.cereal {
    position: absolute;
    width: 70px;
    height: 70px;
    opacity: 0;
    background: no-repeat center/contain;

    @for $i from 1 through 16 {
        &.-c#{$i} {
            background-image: url(../image/16/#{$i}.png);
        }
    }

    @for $i from 1 through 34 {
        &:nth-child(#{$i}) {
            bottom: nth(nth($cerealsPosition, $i), 1) + px;
            right: nth(nth($cerealsPosition, $i), 2) + px;
        }
    }

    @include breakpoint-down('tablet') {
        width: percent(70);
        height: percent(70);

        @for $i from 1 through 34 {
            &:nth-child(#{$i}) {
                bottom: percent(nth(nth($cerealsPosition, $i), 1));
                right: percent(nth(nth($cerealsPosition, $i), 2));
            }
        }
    }
}

@keyframes dashMove {
    from {
        stroke-dashoffset: 800;
    }

    to {
        stroke-dashoffset: 4900;
    }
}

.path {
    /* 線長 空白長 */
    stroke-dasharray: 800 3300;
    /* 位移 */
    stroke-dashoffset: 800;

    body:not(.-wrongBrowser).-loaded & {
        animation: dashMove 3.5s 2s linear;
    }
}